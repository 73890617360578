<template>
<div>
    <div class="content_text">
        <!-- <router-link :to="{path:'/'}">
          <img class="content_img_1" src="../../assets/content/logo_s.png">
        </router-link> -->
        <div class="pagetitle-roundicon">
          <img :src="userAvatar" v-if="userAvatar && userAvatar!='null'" style="width:50px;height:50px;border-radius: 36px;">
        </div>
        <router-link :to="{path:'/my/account'}">
          <div>{{userName}}</div>
        </router-link>
        <div class="pagetitle-btn-con">
          <img src="../../assets/my/vip_icon.png" style="width:60px;height:60px;">
        </div>
    </div>
    <div class="sv_2">
      <div style="width:100%;height:100%;overflow: hidden auto;">
        <router-link :to="{path:'/my/account'}">
          <div class="uni-scroll-views">
            <div>
              <div class="grace grace-list-items grace-border-b_my">
                <div style="width:20px;height:20px;">
                    <img src="../../assets/my/icon_1.jpg" style="width:100%;height:100%;">
                </div>
                <div class="grace-list-body">
                    {{account}}
                </div>
                <div class="grace-list-arrow-right icon-arrow-right grace-white">
                  <img src="../../assets/my/icon.jpg">
                </div>
              </div>
            </div>
          </div>
        </router-link>
        <router-link :to="{path:'/my/reset_password'}">
        <div class="uni-scroll-views">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_2.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  {{reset_password}}
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>
        </router-link>
        <!-- <router-link :to="{path:'/my/faq'}">
        <div class="uni-scroll-views">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_3.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                FAQ
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>
        </router-link> -->
        <!-- <router-link :to="{path:'/task/grade_table',query:{type:'my'}}">
        <div class="uni-scroll-views" @click="grade_table">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_4.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  成績表
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>
        </router-link> -->
        <!-- <div class="uni-scroll-views">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_5.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  排行榜
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div> -->
        <!-- <router-link :to="{path:'/my/point_rule'}">
        <div class="uni-scroll-views">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_6.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  {{the_scoring_rules}}
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>
        </router-link> -->
        <router-link :to="{path:'/my/collect'}">
        <div class="uni-scroll-views">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_7.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  {{my_favorite}}/{{bookmark}}
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>
        </router-link>
        <router-link :to="{path:'/my/activation_record'}">
        <div class="uni-scroll-views">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_8.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  {{activation_record}}
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>
        </router-link>

        <router-link :to="{path:'/share'}">
          <div class="uni-scroll-views">
            <div>
              <div class="grace grace-list-items grace-border-b_my">
                <div style="width:20px;height:20px;">
                    <img src="../../assets/my/icon_9.png" style="width:100%;height:100%;">
                </div>
                <div class="grace-list-body">
                    {{share}}
                </div>
                <div class="grace-list-arrow-right icon-arrow-right grace-white">
                  <img src="../../assets/my/icon.jpg">
                </div>
              </div>
            </div>
          </div>
        </router-link>

        <router-link :to="{path:'/set_font_size'}">
          <div class="uni-scroll-views">
            <div>
              <div class="grace grace-list-items grace-border-b_my">
                <div style="width:20px;height:20px;">
                    <img src="../../assets/my/icon_10.png" style="width:100%;height:100%;">
                </div>
                <div class="grace-list-body">
                    {{set_font_size}}
                </div>
                <div class="grace-list-arrow-right icon-arrow-right grace-white">
                  <img src="../../assets/my/icon.jpg">
                </div>
              </div>
            </div>
          </div>
        </router-link>

        <div class="uni-scroll-views" @click="janeTraditional">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_11.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  {{switch_to_simple_and_traditional}}
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>

        <div class="uni-scroll-views" @click="janeTraditionals">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_12.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  {{cut_use_position}}
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>

        <!-- <router-link :to="{path:'/my/regard_allstar'}">
          <div class="uni-scroll-views">
            <div>
              <div class="grace grace-list-items grace-border-b_my">
                <div style="width:20px;height:20px;">
                    <img src="../../assets/my/icon_13.png" style="width:100%;height:100%;">
                </div>
                <div class="grace-list-body">
                    {{in_regard_to_allstar}}
                </div>
                <div class="grace-list-arrow-right icon-arrow-right grace-white">
                  <img src="../../assets/my/icon.jpg">
                </div>
              </div>
            </div>
          </div>
        </router-link> -->

        <div class="uni-scroll-views" @click="log_out">
          <div>
            <div class="grace grace-list-items grace-border-b_my">
              <div style="width:20px;height:20px;">
                  <img src="../../assets/my/icon_14.png" style="width:100%;height:100%;">
              </div>
              <div class="grace-list-body">
                  登出
              </div>
              <div class="grace-list-arrow-right icon-arrow-right grace-white">
                <img src="../../assets/my/icon.jpg">
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 10px;text-align: center;color:#fff;">v1.1.11</div>


        <div class="grace-btdialog-shade_my" v-if="redeemCode_dialog" @click.stop="redeemCode">
            <div class="dialog_my gdIn_my" @click.stop="redeemCode">
                <div>
                  <div style="width:50%;height:10vh;float:left;"></div>
                  <div style="width:50%;height:10vh;float:left;text-align:center;color: rgb(102, 102, 102);line-height:8vh;" @click="cancel">取消</div>
                </div>
                <div style="padding: 20px;">
                    <button class="grace-buttons_my" @click="chineseCut(2)">{{simplified_chinese}}</button>
                    <button class="grace-buttons_my" @click="chineseCut(1)">{{chinese_traditional}}</button>
                </div>
            </div>
        </div>

        <div class="grace-btdialog-shade_my" v-if="redeemCodes_dialog" @click.stop="redeemCodes">
            <div class="dialog_my gdIn_my" @click.stop="redeemCodes">
                <div>
                  <div style="width:50%;height:10vh;float:left;"></div>
                  <div style="width:50%;height:10vh;float:left;text-align:center;color: rgb(102, 102, 102);line-height:8vh;" @click="cancels">取消</div>
                </div>
                <div style="padding: 20px;">
                    <!-- <button class="grace-buttons_my">在内地使用</button> -->
                    <button class="grace-buttons_my" @click="cutUsePosition(for_use_in_hong_kong_and_macau)">{{for_use_in_hong_kong_and_macau}}</button>
                    <!-- <button class="grace-buttons_my">自動切換</button> -->
                </div>
            </div>
        </div>
        <div style="height:90px;width: 100%;"></div>
      </div>
    </div>
</div>
</template>

<style>
.pagetitle-btn-con{
  margin-left: auto;
  margin-right: 17px;
  display: flex;
}
.grace-buttons_my{
    background: #C49B6C !important;
    width:100%;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    border-radius: 2px;
    margin-top:3vh;
}
.dialog_inp_my{
    width:100%;
    display: block;
    border: 1px solid #999;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    height: 36px;
    color: #333;
    border-radius: 10px;
}
.gdIn_my{
    animation: gdIn-data-v-2f613eb3 .2s linear forwards;
}
.dialog_my{
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0;
}
.grace-btdialog-shade_my{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    background: rgba(0,0,0,.5);
    color: #fff;
}
.grace-white {
  color: #fff!important;
}
.grace-white img{
  margin-top:5px;
}
.grace-list-arrow-right {
  display: block;
  font-size: 18px!important;
  height: 25px!important;
  line-height: 25px!important;
  text-align: right;
  width: 30px;
  color: #b2b2b2;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.grace-list-body{
  text-align: left;
  color: #fff;
  padding: 13px 0;
  margin-left: 13px;
  width: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  flex: auto;
}
.sv_2{
  height: 606px;
}
.grace-border-b_my{
  border-bottom: 1px solid #e9e9e9;
}
.grace-list-items{
      display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.grace{
  padding: 0px 15px;
}
.pagetitle-roundicon{
  width: 50px;
  height: 50px;
  position: relative;
  margin-right: 10px;
}
.content_text{
    border-bottom: 1px solid #fff;
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
</style>

<script>
import {apiLogOut,apiUsers} from '@/request/api';
export default {
  data(){
    return {
      redeemCode_dialog:false,
      redeemCodes_dialog:false,
      userName:'',
      userAvatar:'',
      simplified_chinese:'',
      chinese_traditional:'',
      switch_to_simple_and_traditional:'',
      set_font_size:'',
      activation_record:'',
      bookmark:'',
      my_favorite:'',
      the_scoring_rules:'',
      share:'',
      account:'',
      reset_password:'',
      cut_use_position:'',
      in_regard_to_allstar:'',
      for_use_in_hong_kong_and_macau:'',
    }
  },
  created(){
    let lang_id = this.$store.state.lang_id;
    if(lang_id == 1){
      this.share = this.$zh_ft('share');
      this.simplified_chinese = this.$zh_ft('simplified_chinese');
      this.chinese_traditional = this.$zh_ft('chinese_traditional');
      this.switch_to_simple_and_traditional = this.$zh_ft('switch_to_simple_and_traditional');
      this.set_font_size = this.$zh_ft('set_font_size');
      this.activation_record = this.$zh_ft('activation_record');
      this.bookmark = this.$zh_ft('bookmark');
      this.my_favorite = this.$zh_ft('my_favorite');
      this.the_scoring_rules = this.$zh_ft('the_scoring_rules');
      this.account = this.$zh_ft('account');
      this.reset_password = this.$zh_ft('reset_password');

      this.cut_use_position = this.$zh_ft('cut_use_position');
      this.in_regard_to_allstar = this.$zh_ft('in_regard_to_allstar');
      this.for_use_in_hong_kong_and_macau = this.$zh_ft('for_use_in_hong_kong_and_macau');
    }else{
       this.share = this.$zh_jt('share');
      this.simplified_chinese = this.$zh_jt('simplified_chinese');
      this.chinese_traditional = this.$zh_jt('chinese_traditional');
      this.switch_to_simple_and_traditional = this.$zh_jt('switch_to_simple_and_traditional');
      this.set_font_size = this.$zh_jt('set_font_size');
      this.activation_record = this.$zh_jt('activation_record');
      this.bookmark = this.$zh_jt('bookmark');
      this.my_favorite = this.$zh_jt('my_favorite');
      this.the_scoring_rules = this.$zh_jt('the_scoring_rules');
      this.account = this.$zh_jt('account');
      this.reset_password = this.$zh_jt('reset_password');

      this.cut_use_position = this.$zh_jt('cut_use_position');
      this.in_regard_to_allstar = this.$zh_jt('in_regard_to_allstar');
      this.for_use_in_hong_kong_and_macau = this.$zh_jt('for_use_in_hong_kong_and_macau');
    }
    if(this.$store.state.login_verify){
      this.userName = this.$store.state.user_name;
      this.userAvatar = this.$store.state.user_avatar;
    }
  },
  methods:{
    cutUsePosition(cut_use_position){
      this.redeemCodes_dialog = false;
      // lang_id 1:繁體 2:簡體
      let lang_id = this.$store.state.lang_id;
      if(lang_id == 1){
        this.$message.success('已切換為'+cut_use_position);
      }else{
        this.$message.success('已切换为'+cut_use_position);
      }
    },
    // 切換簡繁體 1：繁體 2：簡體
    chineseCut(val){
      if(val){
        let that = this;
        let user_id = that.$store.state.user_id;
        apiUsers(user_id,{
          lang_id:val
        }).then(res=>{
          if(res.code == 200){
            that.$message.success('切換成功');
            that.$store.state.lang_id = val;
            localStorage.setItem("lang_id", val);
            location.reload();
          }else{
            that.$message.error(res.message);
          }
        })
      }
    },
    cancel(){
      this.redeemCode_dialog = false;
      this.$store.state.switchover = false;
    },
    cancels(){
      this.redeemCodes_dialog = false;
      this.$store.state.switchoverJaneTraditional = false;
    },
    janeTraditional(){
      this.redeemCode_dialog = true;
      this.$store.state.switchover = true;
    },
    janeTraditionals(){
      this.redeemCodes_dialog = true;
      this.$store.state.switchoverJaneTraditional = true;
    },
    redeemCode(val){
        // 点击子元素时 阻止触发父级方法
        let event1 = val.currentTarget;
        let event2 = val.target;
        // 当event1 == event2 则表示点击的是子级以外的所有父级内容 则关闭输入兑换码
        if(event1 == event2){   
            this.redeemCode_dialog = false;
            this.$store.state.switchover = false;
        }
    },
    redeemCodes(val){
        // 点击子元素时 阻止触发父级方法
        let event1 = val.currentTarget;
        let event2 = val.target;
        // 当event1 == event2 则表示点击的是子级以外的所有父级内容 则关闭输入兑换码
        if(event1 == event2){   
            this.redeemCodes_dialog = false;
            this.$store.state.switchoverJaneTraditional = false;
        }
    },
    grade_table(){
      this.$store.state.grade_table = true;
    },
    // 登出
    log_out(){
      apiLogOut().then(res=>{
        if(res.code == 200){
          localStorage.removeItem('token');
          localStorage.removeItem('user_avatar');
          localStorage.removeItem('user_id');
          localStorage.removeItem('user_name');
          localStorage.setItem('lang_id',1);
          localStorage.removeItem('month_goal');
          this.$message.success('登出成功');
          location.href="./";
        }else{
          this.$message.error(res.message);
        }
      }).catch(error => {
        console.log(error);
        return false;
      });
    }
  }
}
</script>
      