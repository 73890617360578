<template>
    <div>
        <div class="sv_3">
            <div class="uni-scroll-view">
                <div style="margin: 30px;text-align: center;">
                    <div style="padding: 20px 0px;color:#fff;">你正在查看免費內容，如需查看更多內容，請登入或注冊</div>
                    <div class="round-btns">
                        <router-link :to="{path:'/login'}">登入/注冊</router-link>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .sv_3{
        width:100%;
        height:687px;
    }
    .uni-scroll-view{
        width:100%;
        height:100%;
        overflow: hidden auto;
    }
    .round-btns {
        display: block;
        color: #fff;
        line-height: 130%;
        font-size: 16px;
        padding: 7px 20px;
        border: 1px solid #fff;
        border-radius: 999999px;
        background-color: initial;
        -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
        transition: all .3s cubic-bezier(.215,.61,.355,1);
    }
    .round-btns a{
        color:#fff;
        text-decoration:none;
    }
</style>
