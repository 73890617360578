<template>
  <div>
    <Header/>
    <Content v-if="status"/>
    <NotLoing v-else />
    <Footer/>
  </div>
</template>

<script>
import Header from "../Header";
import Footer from "../Footer";
import Content from "./content";
import NotLoing from "./not_login";
export default {
  components:{Header,Footer,Content,NotLoing},
  data(){
    return {
      status:false,
    }
  },
  created(){
    if(this.$store.state.login_verify){
      this.status = true;
    }else{
      this.status = false;
    }
  },
  computed:{
      login_verify(){
        return this.$store.state.login_verify;
      }
  },
  watch:{
    login_verify(val){
      if(val){
        this.status = true;
      }else{
        this.status = false;
      }
    }
  },
}
</script>

